<template>
	<div class="exception-body access-denied">
		<div class="exception-panel"></div>

		<div class="exception-content">
			<!-- <img src="layout/images/logo-black.png" alt="babylon-layout"/> -->
			<h1><span class="exception-name">Acesso</span> Negado</h1>
			<p>Você não tem a permissão necessária. {{ 'v' + version }}</p>
		</div>
	</div>
</template>

<script>
import pjson from "@/../package.json";

	export default {
		data() {
			return {
				version: pjson.version,
			};
		},
		methods: {
		}
	}
</script>

<style scoped>

</style>